import React, { ReactNode, MouseEventHandler } from "react";
import {
  ButtonClose,
  ContentButton,
  ContentChildren,
  ContentModal,
} from "../style";
import { motion } from "framer-motion";
import { snappyAnimation } from "../../animations/snappyAnimation";

interface ModalProps {
  onClose: MouseEventHandler;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ onClose, children }) => {
  return (
    <motion.div
      style={{
        position: "absolute",
        width: "80%",
        backgroundColor: "white",
        borderRadius: "10px",
        height: "inherit",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "0 0 0 50000vmax rgba(0,0,0,.5)",
        zIndex: 1,
      }}
      {...snappyAnimation()}
    >
      <ContentButton>
        <ButtonClose className="close-button" onClick={onClose}>
          X
        </ButtonClose>
      </ContentButton>
      <ContentChildren>{children}</ContentChildren>
    </motion.div>
  );
};

export default Modal;
