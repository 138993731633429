export const theme = {
    colors:{
        green: {
            dark:'#2E7D32',
            main:'#39B54A',
            ligth: '#ABCF51'
        },
        purple:{
            main:'#655DD7',
        },
        yellow: {
            main: '#FAB234',
        },
        red: {
            main: '#ED565A',
        },
        blue: {
            dark: '#2E3D7D',
        }
    }
}