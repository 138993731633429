import binGreen from ".././assets/bins/bin-green.png";
import binPurple from ".././assets/bins/bin-purple.png";
import binYellow from ".././assets/bins/bin-yellow.png";
import binRed from ".././assets/bins/bin-red.png";
import homeGreen from ".././assets/homes/home-green.png";
import homePurple from ".././assets/homes/home-purple.png";
import homeYellow from ".././assets/homes/home-yellow.png";
import homeRed from ".././assets/homes/home-red.png";
import itemOneBin from ".././assets/modal/bins/itemOneBin.jpg";
import itemTwoBin from ".././assets/modal/bins/itemTwoBin.jpg";
import itemThreeBin from ".././assets/modal/bins/itemThreeBin.jpg";
import itemFourBin from ".././assets/modal/bins/itemFourBin.jpg";
import itemOneHome from ".././assets/modal/homes/itemOneHome.jpg";
import itemTwoHome from ".././assets/modal/homes/itemTwoHome.jpg";
import itemThreeHome from ".././assets/modal/homes/itemThreeHome.jpg";
import itemFourHome from ".././assets/modal/homes/itemFourHome.jpg";

export const bins = [
  {
    number: 1,
    color: "green",
    icon: binGreen,
    title: "CAPACITACIÓN A NUESTROS RECICLADORES",
    image: itemOneBin,
    colorHEX: "#39B54A",
    description:
      "Proporciona formación especializada a los recicladores sobre las mejores prácticas en la recolección, clasificación y tratamiento de residuos. Se enfoca en mejorar sus habilidades técnicas y conocimientos sobre la gestión sostenible de los materiales reciclables, así como en promover su seguridad y bienestar en el trabajo.",
  },
  {
    number: 2,
    color: "purple",
    icon: binPurple,
    title: "LIMPIEZA Y DESINFECCIÓN DE CUARTOS DE ACOPIO",
    image: itemTwoBin,
    colorHEX: "#655DD7",
    description:
      "Enseña técnicas y procedimientos adecuados para limpiar y desinfectar áreas de almacenamiento de residuos reciclables. Se hacen énfasis en la importancia de mantener condiciones higiénicas y seguras para prevenir la contaminación cruzada y promover la salud de los trabajadores y el medio ambiente.",
  },
  {
    number: 3,
    color: "yellow",
    icon: binYellow,
    title: "LIMPIEZA Y DESINFECCIÓN DE CONTENEDORES",
    image: itemThreeBin,
    colorHEX: "#FAB234",
    description:
      "Capacita en métodos efectivos para limpiar y desinfectar los contenedores utilizados para la recolección y almacenamiento de residuos. Se enfoca en eliminar olores, residuos adheridos y agentes patógenos, manteniendo los contenedores en condiciones óptimas para el reciclaje y la salud pública.",
  },
  {
    number: 4,
    color: "red",
    icon: binRed,
    title: "RUTA DE RECOLECCIÓN",
    image: itemFourBin,
    colorHEX: "#ED565A",
    description:
      "Define y optimiza las rutas utilizadas para recolectar residuos reciclables. Se enfoca en mejorar la eficiencia y la cobertura del servicio de recolección, reduciendo costos y minimizando el impacto ambiental mediante la planificación y el seguimiento adecuado de las rutas.",
  },
];

export const homes = [
  {
    number: 1,
    color: "green",
    icon: homeGreen,
    title: "CAPACITACIÓN EN NORMATIVIDAD VIGENTE",
    image: itemOneHome,
    colorHEX: "#39B54A",
    description:
      "Proporciona información actualizada sobre las leyes y regulaciones relacionadas con el reciclaje, incluyendo normativas locales, nacionales e internacionales. Los participantes aprenderán sobre las responsabilidades legales y las mejores prácticas para cumplir con los requisitos legales en materia de reciclaje.",
  },
  {
    number: 2,
    color: "purple",
    icon: homePurple,
    title: "CHARLAS EN LA BUENA GESTIÓN DE RESIDUOS",
    image: itemTwoHome,
    colorHEX: "#655DD7",
    description:
      "Enseña a los participantes cómo gestionar adecuadamente los residuos, incluyendo cómo depositarlos correctamente en los contenedores correspondientes y cómo utilizar de manera efectiva el sistema de recogida de basura. Se proporcionarán consejos prácticos para reducir la generación de residuos y fomentar su reutilización.",
  },
  {
    number: 3,
    color: "yellow",
    icon: homeYellow,
    title: "ACOMPAÑAMIENTO EN LA GESTIÓN DE MULTIUSUARIOS",
    image: itemThreeHome,
    colorHEX: "#FAB234",
    description:
      "Acompañamiento en gestión documental. Se proporcionarán estrategias para mejorar la segregación de residuos, la organización de puntos de reciclaje y la implementación de medidas para fomentar la participación activa de todos los usuarios en el proceso de reciclaje.",
  },
  {
    number: 4,
    color: "red",
    icon: homeRed,
    title: "RETROALIMENTACIÓN DE LAS TRES PRIMERAS",
    image: itemFourHome,
    colorHEX: "#ED565A",
    description:
      "Revisa y evalúa las prácticas de reciclaje implementadas luego de haber recibido capacitación y charlas sobre normatividad vigente, buena gestión de residuos y acompañamiento en la gestión de multiusuarios. Se brindará retroalimentación sobre los avances, identificando áreas de mejora y destacando los logros alcanzados en el proceso de reciclaje.",
  },
];
