import styled from "styled-components";

export const ContentHowToWork = styled.div`
  display: flex;
  align-items: center;
  height: 70%;
  width: 90%;
  flex-direction: column;
  justify-content: space-around;
  transition: 1s;
  @media (max-width: 560px) {
    justify-content: space-evenly;
  }
`;

export const Question = styled.div<{ isLarge: boolean }>`
  font-size: 1000%;
  color: ${({ theme }) => theme.colors.green.ligth};
  display: ${({ isLarge }) => (isLarge ? "flex" : "none")};
`;

export const Title = styled.div<{ isLarge: boolean }>`
  display: ${({ isLarge }) => (isLarge ? "none" : "flex")};
  flex-direction: column;
  @media (max-width: 560px) {
    margin: -30px;
  }
`;

export const Text = styled.div`
  font-size: 400%;
  color: ${({ theme }) => theme.colors.green.main};
  text-shadow: ${({ theme }) => theme.colors.green.dark} 3px 3px 2px;
  @media (max-width: 560px) {
    text-align: center;
  }
`;

export const Imagotype = styled.div`
  display: flex;
  align-items: center;
  margin-top: -20px;
  align-items: center;
`;

export const Logo = styled.div`
  img {
    width: 180%;
    box-shadow: 3px 3px 2px ${({ theme }) => theme.colors.green.dark};
    border-radius: 360px;
  }
`;

export const Isotype = styled.div`
  font-size: 400%;
  text-shadow: ${({ theme }) => theme.colors.green.dark} 3px 3px 2px;
  margin-left: 15%;
  color: ${({ theme }) => theme.colors.green.main};
  i {
    display: none;
  }
  @media (max-width: 560px) {
    margin-left: 0%;
    i {
      font-style: normal;
      display: contents;
    }
  }
`;

export const ContentBins = styled.div<{ isLarge: boolean; select: boolean }>`
  display: ${({ select }) => (select ? "grid" : "none")};
  width: -webkit-fill-available;
  justify-content: space-around;
  transition: all 20s ease-out;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const BinNumber = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

export const Bin = styled.div`
  img {
    width: 130%;
    height: 100%;
    @media (max-width: 560px) {
      width: 80px;
    }
  }
  z-index: 0;
`;

export const NumberRecyclers = styled.div`
  font-size: 750%;
  position: absolute;
  color: white;
  margin-left: 38px;
  z-index: 1;
  @media (max-width: 560px) {
    font-size: 55px;
    margin-left: 0px;
  }
`;

export const NumberCommunity = styled.div`
  font-size: 750%;
  position: absolute;
  color: white;
  margin-left: 38px;
  z-index: 1;
  @media (max-width: 560px) {
    font-size: 55px;
    margin-left: -18px;
  }
`;

export const ContentHomes = styled.div<{ isLarge: boolean; select: boolean }>`
  display: ${({ select }) => (select ? "flex" : "none")};
  width: -webkit-fill-available;
  justify-content: space-around;
  transition: all 20s;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 560px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const HomeNumber = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all 20s;
`;

export const Home = styled.div`
  img {
    width: 125%;
    height: 300px;
    @media (max-width: 560px) {
      width: 115px;
      height: 140px;
      margin-left: -25px;
      margin-right: -25px;
    }
  }
  transition: all 2s ease-out;
`;

export const ContentTitleSelect = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  transition: 1s;
  flex-wrap: wrap;
  @media (max-width: 560px) {
    align-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
`;

export const BoxSelect = styled.div<{ isLarge: boolean }>`
  display: ${({ isLarge }) => (isLarge ? "none" : "flex")};
  -webkit-box-align: center;
  align-items: center;
  font-size: 150%;
  transition: all 0.2s ease-out;
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const ItemBoxSelect = styled.div<{ select: boolean; name: string }>`
  display: flex;
  border: 2px dashed ${({ theme }) => theme.colors.green.main};
  padding: 15px;
  color: ${({ theme, select }) => (select ? "white" : theme.colors.green.main)};
  background-color: ${({ theme, select }) =>
    select ? theme.colors.green.main : "white"};
  cursor: pointer;
  transition: all 0.2s ease-out;
  border-radius: ${({ name }) =>
    name === "recyclers" ? "10px 0px 0px 10px" : "0px 10px 10px 0px"};
`;

export const ModalTitleImage = styled.div``;

export const ModalNumber = styled.div<{ item: string }>`
  font-size: 2540%;
  text-align: center;
  color: ${({ item }) => item};
  @media (max-width: 860px) {
    font-size: 65px;
  }
`;

export const ModalTitle = styled.div<{ item: string }>`
  font-size: 200%;
  text-align: center;
  border-radius: 50px;
  color: ${({ item }) => item};
  margin-bottom: 20px;
  @media (max-width: 860px) {
    width: 100%;
    font-size: 170%;
  }
`;

export const ModalImage = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  img {
    width: 90%;
    height: 100%;
    border-radius: 30px;
  }
`;

export const ModalImageDescription = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

export const ModalDescription = styled.div`
  display: flex;
  color: black;
  width: 350px;
  height: 100%;
  flex: 1;
  @media (max-width: 860px) {
    width: 90%;
    margin-top: 10px;
    text-align: center;
  }
`;

export const MouseRecyclers = styled.div`
  position: absolute;
  margin-left: -33px;
  margin-top: 20px;
  transform: scaleX(-1);
  pointer-events: none;
  img {
    width: 55px;
    @media (max-width: 560px) {
      width: 45px;
    }
  }
  @media (max-width: 560px) {
    margin-top: 8px;
    margin-left: -25px;
  }
`;

export const MouseCommunity = styled.div`
  position: absolute;
  margin-left: 375px;
  margin-top: 20px;
  pointer-events: none;
  img {
    width: 55px;
    @media (max-width: 560px) {
      width: 45px;
    }
  }
  @media (max-width: 560px) {
    margin-top: 8px;
    margin-left: 253px;
  }
`;
