import {
  ContainerFooter,
  ItemFooter,
  Icon,
  Text,
  MouseNumberOne,
  MouseNumberTwo,
  MouseEmail,
  MouseAddress,
} from "./style";
import address from "../../assets/itemsFooter/address.png";
import celephone from "../../assets/itemsFooter/whatsapp.png";
import email from "../../assets/itemsFooter/email.png";
import NIT from "../../assets/itemsFooter/NIT.png";
import { motion } from "framer-motion";
import mouse from "../../assets/mouse.gif";
import { fadeInAnimation } from "../../animations/fadeInAnimation";

export const Footer = () => {
  return (
    <ContainerFooter>
      <motion.div {...fadeInAnimation()}>
        <ItemFooter color="ligth">
          <Icon>
            <img src={celephone} alt="celephone" />
          </Icon>
          <Text>
            <a
              href="https://api.whatsapp.com/send?phone=3165572673&text=Hola,%20Ecoplanetario!%20%E2%99%BB%EF%B8%8F"
              target="_blank"
              rel="noopener noreferrer"
            >
              3165572673
            </a>{" "}
            -
            <MouseNumberOne>
              <img src={mouse} alt="mouse" />
            </MouseNumberOne>{" "}
            <a
              href="https://api.whatsapp.com/send?phone=3138546300&text=Hola,%20Ecoplanetario!%20%E2%99%BB%EF%B8%8F"
              target="_blank"
              rel="noopener noreferrer"
            >
              3138546300
            </a>
            <MouseNumberTwo>
              <img src={mouse} alt="mouse" />
            </MouseNumberTwo>
          </Text>
        </ItemFooter>
      </motion.div>
      <motion.div {...fadeInAnimation()}>
        <ItemFooter color="dark">
          <Icon>
            <img src={email} alt="facebook" />
          </Icon>
          <Text>
            <a href="mailto:ecoplanetario@hotmail.com">
              ecoplanetario@hotmail.com
            </a>
            <MouseEmail>
              <img src={mouse} alt="mouse" />
            </MouseEmail>
          </Text>
        </ItemFooter>
      </motion.div>
      <motion.div {...fadeInAnimation()}>
        <ItemFooter color="dark">
          <Icon>
            <img src={address} alt="address" />
          </Icon>
          <Text>
            <a
              href="https://maps.app.goo.gl/XW7QaGuBTTajYSuU7"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cra. 5a #11-27, Soacha, Cundinamarca
            </a>
            <MouseAddress>
              <img src={mouse} alt="mouse" />
            </MouseAddress>
          </Text>
        </ItemFooter>
      </motion.div>
      <motion.div {...fadeInAnimation()}>
        <ItemFooter color="ligth">
          <Icon>
            <img src={NIT} alt="facebook" />
          </Icon>
          <Text>NIT. 901.711.139-4</Text>
        </ItemFooter>
      </motion.div>
    </ContainerFooter>
  );
};
