import { useState, useEffect } from 'react';

const useTimer = (time: number) => {
  const [isLarge, setIsLarge] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLarge(false);
    }, time);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return isLarge;
};

export default useTimer;