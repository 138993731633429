export const inclinationAnimation = (isLarge: boolean) => ({
  initial: { opacity: 0, scale: 0.5 },
  animate: {
    opacity: 1,
    scale: 1,
    rotate: isLarge ? 0 : -3,
    transition: { type: "spring", damping: 10 },
  },
  transition: { duration: 0.2 },
  style: {
    zIndex: isLarge ? "0" : "1",
  },
});
