import styled from "styled-components";

export const ContainerBody = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonBack = styled.div`
  cursor: pointer;
  position: absolute;
  display: contents;
  z-index: 5;
  @media (max-width: 560px) {
    img {
      margin-top: 10px;
    }
  }
`;
