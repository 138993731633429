import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const TooltipStyle = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#39B54A",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#39B54A",
    fontSize: "13px",
    padding: "10px",
    margin: "10px",
  },
});

export default TooltipStyle;
