export const scaleXAnimation = (isLarge: boolean) => ({
  animate:
    window.screen.width > 560
      ? { scale: isLarge ? 1 : 0.5, x: isLarge ? "0" : "-20%" }
      : {},
  transition: { duration: 0.5 },
  style:
    window.screen.width > 560
      ? { maxWidth: isLarge ? "" : "50%" }
      : { width: "100%" },
});
