import { useState } from "react";
import { motion } from "framer-motion";
import logo from "../../assets/logo/logo.png";
import facebook from "../../assets/socialMedia/facebook.png";
import instagram from "../../assets/socialMedia/instagram.png";
import whatsapp from "../../assets/socialMedia/whatsapp.png";
import youtube from "../../assets/socialMedia/youtube.png";
import {
  ContainerHeader,
  SocialMedia,
  ItemSocialMedia,
  Imagotype,
  Logo,
  Isotype,
  Slogan,
} from "./style";
import { fadeInAnimation } from "../../animations/fadeInAnimation";
import { fadeInYAnimation } from "../../animations/fadeInYAnimation";

export const Header = () => {
  return (
    <ContainerHeader>
      <motion.div {...fadeInAnimation()}>
        <Imagotype>
          <Logo>
            <img src={logo} alt="logo" />
          </Logo>
          <Isotype>ECOPLANETARIO</Isotype>
        </Imagotype>
      </motion.div>
      <motion.div {...fadeInYAnimation()}>
        <Slogan>¡CONVIERTE TUS RESIDUOS EN DINERO!</Slogan>
        {/* <SocialMedia>
                    <ItemSocialMedia><img src={facebook} alt="facebook" /></ItemSocialMedia>
                    <ItemSocialMedia><img src={instagram} alt="instagram" /></ItemSocialMedia>
                    <ItemSocialMedia><img src={whatsapp} alt="whatsapp" /></ItemSocialMedia>
                    <ItemSocialMedia><img src={youtube} alt="youtube" /></ItemSocialMedia>
                </SocialMedia> */}
      </motion.div>
    </ContainerHeader>
  );
};
