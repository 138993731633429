import {
  Button,
  CloudContainer,
  ContainerButtonMouse,
  ContainerDescription,
  ContainerHomepage,
  ContainerMoneys,
  ContainerMoneysTooltip,
  ContainerTitle,
  ContainerTitleButton,
  Head,
  InconInf,
  Mouse,
  MouseInf,
  P1,
  P2,
  P3,
  P4,
  P5,
  P6,
  Tail,
  Title,
} from "./style";
import head from "../../assets/moneys/head.png";
import tail from "../../assets/moneys/tail.png";
import inf from "../../assets/iconInf/inf.png";
import mouse from "../../assets/mouse.gif";
import useTimer from "../../hooks/useTimerControl";
import { scaleXAnimation } from "../../animations/scaleXAnimation";
import { motion } from "framer-motion";
import { glideAnimation } from "../../animations/glideAnimation";
import { gesturesAnimation } from "../../animations/gesturesAnimation";
import { inclinationAnimation } from "../../animations/inclinationAnimation";
import { xGlideAnimation } from "../../animations/xGlideAnimation";
import { fadeInAnimation } from "../../animations/fadeInAnimation";
import { whileTap } from "../../animations/whileTap";
import { Tooltip, Zoom } from "@mui/material";
import TooltipStyle from "../../components/tooltip/Tooltip";
import { pulseAnimation } from "../../animations/pulseAnimation";

interface HomepageProps {
  onClick: () => void; // Tipo para la función onClick
}

export const Homepage = ({ onClick }: HomepageProps) => {
  const handleClick = () => {
    onClick();
    document.body.classList.add("image-background-green"); // Llama a la función onClick pasada desde App
  };

  return (
    <ContainerHomepage isLarge={useTimer(3000)}>
      <motion.div {...scaleXAnimation(useTimer(3000))}>
        <ContainerTitleButton>
          <ContainerTitle>
            <motion.div {...fadeInAnimation()}>
              <Title>
                <i>PROYECTO</i>
                <br />
                MIL X MIL
              </Title>
            </motion.div>
            <ContainerMoneysTooltip>
              <ContainerMoneys>
                <motion.div {...xGlideAnimation(7000, 7000)}>
                  <motion.div {...gesturesAnimation()}>
                    <Tail>
                      <img src={tail} alt="tail" />
                    </Tail>
                  </motion.div>
                </motion.div>
                <motion.div {...xGlideAnimation(-7000, -7000)}>
                  <motion.div {...gesturesAnimation()}>
                    <Head>
                      <img src={head} alt="head" />
                    </Head>
                  </motion.div>
                </motion.div>
              </ContainerMoneys>
              <motion.div {...pulseAnimation()}>
                <InconInf isLarge={useTimer(5000)}>
                  <TooltipStyle
                    title="
                          La moneda de mil pesos en Colombia, con su imagen representativa de la tortuga marina, 
                          simboliza la importancia de la conservación ambiental y la protección de las tortugas marinas. 
                          Estas criaturas migratorias enfrentan amenazas, incluida la ingestión de plásticos, que pueden llevar a su muerte. 
                          Reducir el uso de plásticos, como optar por botellas reutilizables, ayuda a proteger a las tortugas y 
                          a promover un entorno más limpio y saludable."
                    placement="top"
                    enterTouchDelay={0}
                    leaveTouchDelay={20000}
                  >
                    <img src={inf} alt="inf" />
                  </TooltipStyle>
                  <MouseInf>
                    <img src={mouse} alt="mouse" />
                  </MouseInf>
                </InconInf>
              </motion.div>
            </ContainerMoneysTooltip>
          </ContainerTitle>
        </ContainerTitleButton>
      </motion.div>
      <ContainerDescription isLarge={useTimer(3000)}>
        <motion.div {...inclinationAnimation(useTimer(4700))}>
          <motion.div {...glideAnimation(useTimer(3400))}>
            <P1>¡HAGA CUENTAS!</P1>
          </motion.div>
        </motion.div>
        <motion.div {...glideAnimation(useTimer(3700))}>
          <P2>Ahorre entre</P2>
        </motion.div>
        <motion.div {...glideAnimation(useTimer(4000))}>
          <P3>
            <CloudContainer>
              <P4>$25'000.000</P4>
            </CloudContainer>{" "}
            <P5>y</P5>
            <CloudContainer>
              <P4>$95'000.000</P4>
            </CloudContainer>
          </P3>
        </motion.div>
        <motion.div {...glideAnimation(useTimer(4300))}>
          <P6>CADA AÑO</P6>
        </motion.div>
        <motion.div
          {...inclinationAnimation(useTimer(5000))}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ContainerButtonMouse isLarge={useTimer(5000)}>
            <motion.div {...whileTap()}>
              <Button onClick={handleClick}>¿CÓMO?</Button>
            </motion.div>
            <Mouse>
              <img src={mouse} alt="mouse" />
            </Mouse>
          </ContainerButtonMouse>
        </motion.div>
      </ContainerDescription>
    </ContainerHomepage>
  );
};
