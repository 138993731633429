export const jumpPulseAnimation = () => ({
  animate: {
    scale: [1, 0.95, 1],
    y: [1, 0.95, 1],
  },
  transition: {
    duration: 1,
    repeat: Infinity,
  },
});
