import { useEffect, useState } from "react";
import { Header, Footer } from "./core";
import { ButtonBack, ContainerBody } from "./style";
import { Homepage, HowToWork } from "./modules";
import back from "./assets/back.png";
import useTimer from "./hooks/useTimerControl";

function App() {
  useEffect(() => {
    setTimeout(function () {
      document.body.classList.add("image-background");
    }, 3000);
  }, []);

  const [showHowToWork, setShowHowToWork] = useState(false); // Estado para controlar si se muestra HowToWork o Homepage

  const handleClick = (state: boolean) => {
    setShowHowToWork(state); // Al hacer click, se muestra HowToWork
  };

  const handleClickBack = (state: boolean) => {
    setShowHowToWork(state); // Al hacer click, se muestra HowToWork
    document.body.classList.remove("image-background-green");
    document.body.classList.remove("image-background");
    setTimeout(function () {
      document.body.classList.remove("green-background");
      document.body.classList.add("image-background");
    }, 3000);
  };

  const useTimerBack = useTimer(4000);

  return (
    <ContainerBody>
      <Header />
      {showHowToWork ? (
        <>
          <ButtonBack onClick={() => handleClickBack(false)}>
            <img src={back} alt="back" />
          </ButtonBack>
          <HowToWork onClick={() => handleClick(false)} />
        </>
      ) : (
        <Homepage onClick={() => handleClick(true)} />
      )}
      <Footer />
    </ContainerBody>
  );
}

export default App;
