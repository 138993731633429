import styled from "styled-components";

export const ContainerHomepage = styled.div<{ isLarge: boolean }>`
  width: 90%;
  display: flex;
  flex: 1;
  -webkit-box-align: center;
  align-items: center;
  justify-content: ${({ isLarge }) => (isLarge ? "center" : "space-evenly")};
  flex-wrap: wrap;
  align-content: center;
  @media (max-width: 560px) {
    width: 100%;
    justify-content: center;
    margin-top: -50px;
    align-content: space-evenly;
  }
`;

export const ContainerTitleButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  background-image: url("./assets/cursor/turtle-dark.png");
  transition: 1s;
  @media (max-width: 560px) {
    flex-wrap: wrap;
    margin-top: 35px;
    justify-content: center;
    margin-right: 30px;
  }
`;

export const Title = styled.div`
  font-size: 900%;
  line-height: 0.8;
  text-align: center;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.green.dark};
  @media (max-width: 560px) {
    font-size: 40px;
  }

  i {
    font-style: normal;
    -webkit-text-stroke: 5px ${({ theme }) => theme.colors.green.dark};
    color: transparent;
    @media (max-width: 560px) {
      -webkit-text-stroke: 2px ${({ theme }) => theme.colors.green.dark};
    }
  }
`;

export const ContainerMoneysTooltip = styled.div`
  display: flex;
`;

export const ContainerMoneys = styled.div`
  img {
    width: 100%;
    @media (max-width: 560px) {
      width: 80px;
    }
  }
`;

export const Tail = styled.div`
  position: relative;
  bottom: -50px;
  left: 40px;
`;

export const Head = styled.div`
  position: relative;
  top: -50px;
`;

export const InconInf = styled.div<{ isLarge: boolean }>`
  display: ${({ isLarge }) => (isLarge ? "none" : "flex")};
  @media (max-width: 560px) {
  }
  img {
    @media (max-width: 560px) {
      position: absolute;
      width: 25px;
      top: 20px;
    }
  }
`;

export const ContainerDescription = styled.div<{ isLarge: boolean }>`
  display: ${({ isLarge }) => (isLarge ? "none" : "flex")};
  flex-direction: column;
  font-size: 300%;
  width: 50%;
  @media (max-width: 560px) {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    font-size: 25px;
    margin-top: -50px;
  }
`;

export const P1 = styled.div`
  background-color: ${({ theme }) => theme.colors.green.dark};
  padding: 15px;
  color: white;
  margin: 5px;
  text-align: center;
`;

export const P2 = styled.div`
  background-color: ${({ theme }) => theme.colors.green.ligth};
  color: white;
  margin: 5px;
  text-align: center;
`;
export const P3 = styled.div`
  color: white;
  text-align: center;
  display: flex;
  font-size: 100%;
  justify-content: space-between;
`;
export const P4 = styled.div`
  color: white;
  margin: 10px;
  text-align: center;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

export const P5 = styled.div`
  color: ${({ theme }) => theme.colors.green.dark};
  margin: 5px;
  text-align: center;
`;

export const P6 = styled.div`
  border: 4px dashed ${({ theme }) => theme.colors.green.dark};
  color: ${({ theme }) => theme.colors.green.dark};
  margin: 5px;
  text-align: center;
`;

export const CloudContainer = styled.div`
  background: ${({ theme }) => theme.colors.blue.dark};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

  &::after,
  &::before {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.blue.dark};
    z-index: -1;
  }

  &::after {
    width: 120px;
    height: 120px;
    bottom: 35px;
    left: 60px;
    shape-outside: circle(50% at 30%);
    clip-path: circle(50% at 0%);
    rotate: 270deg;
  }

  &::before {
    width: 120px;
    height: 100px;
    bottom: 43px;
    right: 55px;
    border-radius: 100%;
    shape-outside: circle(50% at 30%);
    clip-path: circle(50% at 0%);
    rotate: 270deg;
  }

  @media (max-width: 560px) {
    &::after {
      width: 45px;
      height: 100px;
      bottom: -15px;
      left: 60px;
    }

    &::before {
      width: 50px;
      height: 100px;
      right: 55px;
      bottom: -12px;
    }
  }
`;

export const ContainerButtonMouse = styled.div<{ isLarge: boolean }>`
  display: ${({ isLarge }) => (isLarge ? "none" : "flex")};
  align-items: center;
  width: max-content;
  transition: 10s;
`;

export const Mouse = styled.div`
  position: absolute;
  margin-left: 155px;
  margin-top: 60px;
  z-index: 5;
  pointer-events: none;
  img {
    width: 75px;
    @media (max-width: 560px) {
      width: 50px;
    }
  }
  @media (max-width: 560px) {
    margin-left: 85px;
  }
`;

export const MouseInf = styled.div`
  position: absolute;
  margin-left: 35px;
  margin-top: 20px;
  pointer-events: none;
  img {
    width: 65px;
    @media (max-width: 560px) {
      width: 25px;
    }
  }
  @media (max-width: 560px) {
    margin-top: 8px;
    margin-left: 13px;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.green.main};
  color: white;
  border-radius: 50px;
  border: none;
  padding: 30px;
  cursor: pointer;
  box-shadow: 1px 6px ${({ theme }) => theme.colors.green.dark};
  line-height: 1px;
  font-size: 30px;
  transition: 0.5s;
  margin-top: 20px;
  &:active {
    box-shadow: none;
    transform: translateY(10px);
    transition: 0.05s;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.green.ligth};
    color: white;
  }
  @media (max-width: 560px) {
    font-size: 20px;
    padding: 15px;
  }
`;
