import { useState } from "react";

interface HomeOrBin {
  number: number;
  color: string;
  image: string;
  icon: string;
  title: string;
  colorHEX: string;
  description: string;
}

const useModal = () => {
  const [modalItem, setModalItem] = useState<HomeOrBin | null>(null);

  const handleItemClick = (item: HomeOrBin) => {
    setModalItem(item);
  };

  const handleCloseModal = () => {
    setModalItem(null);
  };

  return {
    modalItem,
    handleItemClick,
    handleCloseModal,
  };
};

export default useModal;
