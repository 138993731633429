import styled from "styled-components";

export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: large;
  flex-wrap: wrap;
  @media (max-width: 560px) {
    font-size: 15px;
    flex-direction: column;
  }
`;

export const ItemFooter = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: ${({ theme }) => theme.colors.green.dark};
  height: auto;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.div`
  margin-right: 10px;
  img {
    width: 40px;
    @media (max-width: 560px) {
      width: 25px;
    }
  }
`;

export const Text = styled.div`
  font-size: large;
  a {
    color: ${({ theme }) => theme.colors.green.dark};
  }
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const MouseNumberOne = styled.div`
  position: absolute;
  margin-top: -50px;
  margin-left: 100px;
  rotate: -90deg;
  pointer-events: none;
  img {
    width: 35px;
    @media (max-width: 560px) {
      width: 20px;
    }
  }
  @media (max-width: 560px) {
    margin-top: -35px;
    margin-left: 85px;
  }
`;

export const MouseNumberTwo = styled.div`
  position: absolute;
  margin-top: -50px;
  margin-left: 218px;
  rotate: -90deg;
  pointer-events: none;
  img {
    width: 35px;
    @media (max-width: 560px) {
      width: 20px;
    }
  }
  @media (max-width: 560px) {
    margin-top: -35px;
    margin-left: 183px;
  }
`;

export const MouseEmail = styled.div`
  position: absolute;
  margin-top: -40px;
  margin-left: 225px;
  rotate: -90deg;
  pointer-events: none;
  img {
    width: 35px;
    @media (max-width: 560px) {
      width: 20px;
    }
  }
  @media (max-width: 560px) {
    margin-top: -30px;
    margin-left: 190px;
  }
`;

export const MouseAddress = styled.div`
  position: absolute;
  margin-top: -40px;
  margin-left: 310px;
  rotate: -90deg;
  pointer-events: none;
  img {
    width: 35px;
    @media (max-width: 560px) {
      width: 20px;
    }
  }
  @media (max-width: 560px) {
    margin-top: -30px;
    margin-left: 258px;
  }
`;
