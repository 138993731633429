import styled from "styled-components";

export const ContainerHeader = styled.div`
  height: auto;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px 5px 0px 5px;

  @media (max-width: 560px) {
    justify-content: center;
    flex-direction: column;
  }
`;
export const Imagotype = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Logo = styled.div`
  img {
    width: 60px;
    @media (max-width: 560px) {
      width: 40px;
    }
  }
`;

export const Isotype = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.green.ligth};
`;

export const SocialMedia = styled.div`
  background-color: ${({ theme }) => theme.colors.green.dark};
  -webkit-box-align: center;
  align-items: center;
  border-radius: 0px 0px 120px 120px;
  display: flex;
  justify-content: center;
  width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
`;

export const Slogan = styled.div`
  background-color: ${({ theme }) => theme.colors.green.dark};
  font-size: 20px;
  color: white;
  border-radius: 0px 0px 120px 120px;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  @media (max-width: 560px) {
    font-size: 14px;
    padding: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const ItemSocialMedia = styled.div`
  cursor: pointer;
  margin: 10px;
  font-style: italic;

  img {
    width: 50px;
  }

  &:hover {
    transition: 0.5s;
    transform: rotate3d(1, 1, 1, 5deg);
  }
`;
