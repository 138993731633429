export const scaleMaxAnimation = (isLarge: boolean, isLarge2: boolean) => ({
  initial: {
    scale: window.screen.width > 560 ? 0.5 : 0.2,
    x: window.screen.width > 560 ? "20%" : "0%",
    y: "80%",
    opacity: 0,
  },
  animate: {
    scale: window.screen.width > 560 ? 1 : 0.4,
    x: "0%",
    y: "30%",
    opacity: 1,
    rotateY: isLarge ? 0 : 90,
  },
  style: { display: isLarge2 ? "flex" : "none" },
  transition: { duration: 0.5 },
});
