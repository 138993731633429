import {
  Bin,
  BinNumber,
  BoxSelect,
  ContentBins,
  ContentHomes,
  ContentHowToWork,
  ContentTitleSelect,
  Home,
  HomeNumber,
  Imagotype,
  Isotype,
  ItemBoxSelect,
  Logo,
  ModalDescription,
  ModalImage,
  ModalImageDescription,
  ModalNumber,
  ModalTitle,
  ModalTitleImage,
  MouseCommunity,
  MouseRecyclers,
  NumberCommunity,
  NumberRecyclers,
  Question,
  Text,
  Title,
} from "./style";
import useTimer from "../../hooks/useTimerControl";
import logo from "../../assets/logo/logo.png";
import { motion } from "framer-motion";
import { scaleMaxAnimation } from "../../animations/scaleMaxAnimation";
import { rotationXAnimation } from "../../animations/rotationXAnimation";
import { useState } from "react";
import back from "../../assets/back.png";
import { rotationXWhileTapAnimation } from "../../animations/rotationXWhileTapAnimation";
import { bins, homes } from "../../constants/dataItemsHowToWork";
import mouse from "../../assets/mouse.gif";
import Modal from "../../components/modal/Modal";
import useModal from "../../components/modal/useModal";
import { jumpPulseAnimation } from "../../animations/jumpPulseAnimation";

interface HeaderProps {
  onClick: () => void; // Tipo para la función onClick
}

export const HowToWork = ({ onClick }: HeaderProps) => {
  const [selectItem, setSelectItem] = useState("recyclers"); //Variable que verifica el estado del selector
  const { modalItem, handleItemClick, handleCloseModal } = useModal(); //Modal
  const timerItems = useTimer(2800); //setTimeout de los Items

  return (
    <>
      <ContentHowToWork>
        <motion.div {...scaleMaxAnimation(useTimer(600), useTimer(1000))}>
          <Question isLarge={useTimer(1500)}>¿CÓMO?</Question>
        </motion.div>
        <ContentTitleSelect>
          <motion.div {...rotationXAnimation(useTimer(1500))}>
            <Title isLarge={useTimer(1500)}>
              <Text>TRABAJA CON</Text>
              <Imagotype>
                <Isotype>
                  ECOPLANETARI<i>O</i>
                </Isotype>
                <Logo>
                  <img src={logo} alt="logo" />
                </Logo>
              </Imagotype>
            </Title>
          </motion.div>
          <BoxSelect isLarge={useTimer(2800)}>
            <MouseRecyclers>
              <img src={mouse} alt="mouse" />
            </MouseRecyclers>
            <ItemBoxSelect
              onClick={() => setSelectItem("recyclers")}
              select={selectItem === "recyclers"}
              name="recyclers"
            >
              RECICLADORES
            </ItemBoxSelect>
            <ItemBoxSelect
              onClick={() => setSelectItem("community")}
              select={selectItem === "community"}
              name="community"
            >
              COMUNIDAD
            </ItemBoxSelect>
            <MouseCommunity>
              <img src={mouse} alt="mouse" />
            </MouseCommunity>
          </BoxSelect>
        </ContentTitleSelect>

        <ContentBins isLarge={timerItems} select={selectItem === "recyclers"}>
          {bins.map((bin, index) => (
            <motion.div
              onClick={() => handleItemClick(bin)}
              key={index}
              {...rotationXWhileTapAnimation(timerItems)}
            >
              <BinNumber {...jumpPulseAnimation()}>
                <NumberRecyclers>{bin.number}</NumberRecyclers>
                <Bin>
                  <img src={bin.icon} alt={`bin${bin.color}`} />
                </Bin>
              </BinNumber>
            </motion.div>
          ))}
        </ContentBins>

        <ContentHomes isLarge={timerItems} select={selectItem === "community"}>
          {homes.map((home, index) => (
            <motion.div
              onClick={() => handleItemClick(home)}
              key={index}
              {...rotationXWhileTapAnimation(timerItems)}
            >
              <HomeNumber>
                <NumberCommunity>{home.number}</NumberCommunity>
                <Home>
                  <img src={home.icon} alt={`home${home.color}`} />
                </Home>
              </HomeNumber>
            </motion.div>
          ))}
        </ContentHomes>

        {modalItem && (
          <Modal onClose={handleCloseModal}>
            <ModalNumber item={modalItem.colorHEX}>
              {modalItem.number}
            </ModalNumber>
            <ModalTitleImage>
              <ModalTitle item={modalItem.colorHEX}>
                {modalItem.title}
              </ModalTitle>
              <ModalImageDescription>
                <ModalImage>
                  <img src={modalItem.image} alt={modalItem.title} />
                </ModalImage>
                <ModalDescription>{modalItem.description}</ModalDescription>
              </ModalImageDescription>
            </ModalTitleImage>
          </Modal>
        )}
      </ContentHowToWork>
    </>
  );
};
