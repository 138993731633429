import styled from "styled-components";

export const ContentModal = styled.div`
  position: absolute;
  background-color: green;
  width: 80%;
  height: inherit;
`;

export const ModalItem = styled.div`
  font-size: 200px;
`;

export const ContentButton = styled.div`
  display: flex;
  position: relative;
  width: -webkit-fill-available;
  justify-content: flex-end;
  background-color: #80808040;
  border-radius: 10px 10px 0px 0px;
`;

export const ContentChildren = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media (max-width: 860px) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 50px;
    overflow-y: auto;
    align-items: center;
  }
`;

export const ButtonClose = styled.button`
  border: none;
  margin: 10px;
  font-size: 150%;
  cursor: pointer;
  color: #a9a9a9;
  background-color: transparent;
  height: 30px;
  width: 30px;
  justify-content: flex-end;
`;
